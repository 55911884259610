@tailwind base;
@tailwind components;
@tailwind utilities;

html, body, #root {
  margin: 0;
  padding: 0;
  width: 100vw;
  background-color: black;
  overflow-x: hidden;
}

body {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}

#root {
  flex: 1;
  display: flex;
  flex-direction: column;
}

.App {
  flex: 1;
  display: flex;
  flex-direction: column;
}

@media (min-width: 1441px) {
  #root {
    width: 1440px;
    margin: 0 auto;
  }
}

@media (max-width: 1440px) {
  #root {
    width: 100%;
  }
}

@import 'leaflet/dist/leaflet.css';

.leaflet-tile-pane .labels-layer,
.leaflet-tile-pane .map-labels,
.leaflet-tile-pane .continent-labels,
.leaflet-tile-pane .country-labels,
.leaflet-tile-pane .leaflet-tile {
  filter: none !important;
  color: #ffffff !important;
  font-weight: 300 !important;
  font-size: 12px !important;
  text-shadow: none !important;
  -webkit-text-stroke: 0 !important;
  text-stroke: 0 !important;
}

.leaflet-tile-pane .labels-layer {
  opacity: 1 !important;
}

.leaflet-container {
  font-family: 'Helvetica Neue', Arial, sans-serif;
}

.leaflet-tile-pane img {
  filter: invert(1) hue-rotate(180deg) brightness(0.8) contrast(1.2) !important;
}

.leaflet-tile-container img {
  filter: invert(1) hue-rotate(180deg) brightness(0.9) contrast(1.1) !important;
}

.leaflet-container .leaflet-tile-pane .leaflet-layer .leaflet-tile-loaded {
  opacity: 1 !important;
}

.leaflet-tile-pane .leaflet-layer {
  opacity: 1 !important;
}
.leaflet-container .leaflet-control-attribution {
  background-color: rgba(0, 0, 0, 0.5) !important;
  color: #fff !important;
}

.leaflet-container .leaflet-control-zoom a {
  background-color: rgba(0, 0, 0, 0.5) !important;
  color: #fff !important;
}

.leaflet-container .leaflet-popup-content-wrapper,
.leaflet-container .leaflet-popup-tip {
  background-color: rgba(0, 0, 0, 0.8) !important;
  color: #fff !important;
}

.leaflet-popup-content img {
  max-width: 100%;
  height: auto;
  margin-top: 10px;
  margin-bottom: 10px;
}

.leaflet-popup-content a {
  display: block;
  margin-top: 10px;
  color: #4a90e2;
  text-decoration: none;
}

.leaflet-popup-content a:hover {
  text-decoration: underline;
}

.custom-popup .leaflet-popup-content-wrapper {
  background-color: rgba(255, 255, 255, 0.9);
  border-radius: 8px;
  box-shadow: 0 3px 14px rgba(0,0,0,0.4);
}

.custom-popup .leaflet-popup-content {
  margin: 15px 20px;
  color: #333;
}

.custom-popup .leaflet-popup-tip-container {
  display: none;
}

.custom-popup h3 {
  color: #2c3e50;
  margin-bottom: 10px;
}

.custom-popup p {
  margin-bottom: 5px;
}

.custom-popup a {
  display: inline-block;
  margin-top: 10px;
  padding: 5px 10px;
  background-color: #3498db;
  color: white !important;
  text-decoration: none;
  border-radius: 4px;
  transition: background-color 0.3s ease;
}

.custom-popup a:hover {
  background-color: #2980b9;
}

.leaflet-popup-content-wrapper {
  background-color: transparent !important;
  box-shadow: none !important;
}

.leaflet-popup-tip {
  display: none !important;
}

.leaflet-popup-content {
  margin: 0 !important;
  width: auto !important;
  min-width: 0 !important;
}

.custom-popup {
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.3);
}

.leaflet-container a.leaflet-popup-close-button {
  color: #fff;
  top: 5px;
  right: 5px;
}

.leaflet-container a.leaflet-popup-close-button:hover {
  color: #f8f8f8;
  background-color: rgba(255, 255, 255, 0.2);
  border-radius: 50%;
}

.custom-popup h3 {
  color: white;
}

.custom-popup a {
  color: white;
}

.custom-popup img {
  max-width: 100%;
  height: auto;
  object-fit: cover;
  border-radius: 4px;
  margin-bottom: 10px;
}

.custom-cluster-icon {
  background-color: rgba(0, 0, 0, 0.6);
  border-radius: 50%;
  color: white;
  font-weight: bold;
  display: flex;
  align-items: center;
  justify-content: center;
}

.leaflet-fade-anim .leaflet-tile,.leaflet-zoom-anim .leaflet-zoom-animated {
  will-change: auto !important;
}

@keyframes pulse-size {
  0%, 100% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.1);
  }
}

.animate-pulse-size {
  animation: pulse-size 2s ease-in-out infinite;
}

@keyframes float {
  0%, 100% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-10px);
  }
}

.animate-float {
  animation: float 2s ease-in-out infinite;
}

@keyframes float-vibrate {
  0%, 100% {
    transform: translateY(0) rotate(0deg);
  }
  25% {
    transform: translateY(-10px) rotate(0deg);
  }
  30%, 45% {
    transform: translateY(-10px) rotate(-1deg);
  }
  35%, 40% {
    transform: translateY(-10px) rotate(1deg);
  }
  50% {
    transform: translateY(-10px) rotate(0deg);
  }
  75% {
    transform: translateY(0) rotate(0deg);
  }
}

.animate-float-vibrate {
  animation: float-vibrate 2s ease-in-out infinite;
}
