/* We Bring Web3 to Real Life */
.hero-title {
  color: white;
  font-size: 80px;
  font-weight: bold;
  font-family: 'PT Sans', sans-serif;
  line-height: 80px;
}

/* It is a protocol that allows you to use your NFT... */
.hero-description {
  color: white;
  font-size: 1.5rem; /* 24px */
  font-weight: normal;
  font-family: 'PT Sans', sans-serif;
  line-height: 1.75rem; /* 28px */
}

/* Explore More */
.explore-more {
  color: white;
  font-size: 1.25rem; /* 20px */
  font-weight: normal;
  font-family: 'PT Sans', monospace;
  line-height: 2.25rem; /* 36px */
}

/* Hidemeplease APP */
.app-title {
  color: white;
  font-size: 2.25rem; /* 36px */
  font-weight: bold;
  font-family: 'PT Sans', monospace;
  line-height: 2.5rem; /* 40px */
}

/* Map3Protocol Ecosystem */
.ecosystem-title {
  color: white;
  font-size: 3rem; /* 48px */
  font-weight: bold;
  font-family: 'PT Sans', monospace;
  line-height: 72px;
}

/* Tokenomics Visit to Earn(V2E) */
.tokenomics-title {
  color: white;
  font-size: 2.25rem; /* 36px */
  font-weight: bold;
  font-family: 'PT Sans', monospace;
  line-height: 2.5rem; /* 40px */
}

/* F&B Partners */
.partners-label {
  color: #ef5230;
  font-size: 1.5rem; /* 24px */
  font-weight: normal;
  font-family: 'PT Sans', sans-serif;
  line-height: normal;
}

/* 80 + */
.partners-number {
  color: #ef5230;
  font-size: 3rem; /* 48px */
  font-weight: bold;
  font-family: 'PT Sans', sans-serif;
  line-height: 72px;
}

/* Users */
.users-label {
  color: #ef5230;
  font-size: 1.5rem; /* 24px */
  font-weight: normal;
  font-family: 'PT Sans', sans-serif;
  line-height: normal;
}

/* 15,300 + */
.users-number {
  color: #ef5230;
  font-size: 3rem; /* 48px */
  font-weight: bold;
  font-family: 'PT Sans', sans-serif;
  line-height: 72px;
}

/* ... (continued in next message due to character limit) ... */

.stock-image-container {
  position: relative;
  z-index: 0;
}

.content-container {
  position: relative;
  z-index: 1;
}

@keyframes fallAndFade {
  0% {
    opacity: 0;
    transform: translateY(-100px);
  }
  20% {
    opacity: 1;
    transform: translateY(0);
  }
  80% {
    opacity: 1;
    transform: translateY(0);
  }
  100% {
    opacity: 0;
    transform: translateY(100px);
  }
}

/* Add these styles to hide the scrollbar */
body {
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;     /* Firefox */
}

body::-webkit-scrollbar {
  display: none;            /* Chrome, Safari and Opera */
}

/* If you want to only hide scrollbar for the main container, use this instead */
.overflow-x-hidden::-webkit-scrollbar {
  display: none;
}

.overflow-x-hidden {
  -ms-overflow-style: none;
  scrollbar-width: none;
}
